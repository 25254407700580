import axiosIns from "@/libs/axios";

const get = async () => {
  return await axiosIns.get(`configuracion`);
};

const init = async () => {
  return await axiosIns.get(`public/configuracion/init`);
};

const update = async (id, data) => {
  return await axiosIns.patch(`configuracion/${id}`, data);
};

const getDepartamentos = async () => {
  return await axiosIns.get(`departamentos`);
};

const getGirosComerciales = async () => {
  return await axiosIns.get(`giro-comercial`);
};

export default {
  get,
  init,
  update,
  getDepartamentos,
  getGirosComerciales,
};
